let getAccessTokenSilentlyRef: (() => Promise<string>) | null = null;

export const initializeAuthTokenHelper = (
  getAccessTokenSilently: () => Promise<string>,
) => {
  getAccessTokenSilentlyRef = getAccessTokenSilently
};

export const getAccessToken = async (): Promise<string> => {
  if (!getAccessTokenSilentlyRef) {
    throw new Error("Auth Token Helper has not been initialized.");
  }

  const authToken = await getAccessTokenSilentlyRef();
  return authToken;
};