import { FormEvent, useEffect, useRef, useState } from "react";
import { useAssistantsDialog } from "../../components/AssistantsDialog";
import { useAssistants } from "../../state/assistants";
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack } from "@chakra-ui/react";
import { Alias } from "../../gen-ts/slack/slack_config_pb";
import { MdKeyboardArrowDown } from "react-icons/md";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAddAlias: (name: string, alias: Alias) => void;
}
const AddAliasDialog: React.FC<Props> = ({ isOpen, onClose, onAddAlias }) => {
  const initialRef = useRef(null);
  const assistants = useAssistants();
  const assistantDialog = useAssistantsDialog();
  const [ aliasName, setAliasName ] = useState('');
  const [ aliasAssistantId, setAliasAssistantId ] = useState<string | null>(null);

  const selectedAssistant = aliasAssistantId ? assistants.assistants[ aliasAssistantId ] : null;


  useEffect(() => {
    if (isOpen) {
      setAliasName('');
      setAliasAssistantId(null);
    }

  }, [ isOpen ]);

  const disabled = !aliasAssistantId || aliasName.trim() === '';

  const onAdd = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (disabled) {
      return;
    }

    onAddAlias(aliasName, new Alias({ assistantId: aliasAssistantId! }));
    onClose();

  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef} isCentered>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onAdd}>
          <ModalHeader>Create channel alias</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>

              <FormControl>
                <FormLabel>
                  Alias Name
                </FormLabel>
                <Input
                  ref={initialRef}
                  value={aliasName}
                  onChange={(event) => {
                    setAliasName(event.target.value);
                  }}
                />
              </FormControl>

              <Button
                sx={{ width: '100%' }}
                variant="outline"
                rightIcon={<MdKeyboardArrowDown />}
                onClick={() => {
                  assistantDialog.open({
                    onSelect: (assistantId) => {
                      setAliasAssistantId(assistantId);
                    }
                  });
                }}
              >
                {
                  selectedAssistant ? selectedAssistant?.displayName : 'Select Assistant'
                }
              </Button>
            </VStack>

          </ModalBody>
          <ModalFooter>
            <Button colorScheme='primary' mr={3} type="submit" isDisabled={disabled}>
              Save
            </Button>
            <Button variant='ghost' onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}


export default AddAliasDialog;