// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file ai/stores.proto (package ai, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// buf:lint:ignore PACKAGE_VERSION_SUFFIX

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Struct, Timestamp } from "@bufbuild/protobuf";
import { StringList } from "./type/list_pb";

/**
 * A store where files can be uploaded for indexing
 *
 * @generated from message ai.Store
 */
export class Store extends Message<Store> {
  /**
   * id of the store
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * display name of the store
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * Indicates the entity that manages the contents of the store.
   *
   * @generated from field: string managed_by = 8;
   */
  managedBy = "";

  /**
   * Legacy owner field.
   * This will contain the first element of the `owners` field.
   * Deprecated: See `owners` field.
   *
   * @generated from field: string owner = 3 [deprecated = true];
   * @deprecated
   */
  owner = "";

  /**
   * Users that own the store.
   * Values must be in the form `user:username@example.com`
   *
   * @generated from field: repeated string owners = 6;
   */
  owners: string[] = [];

  /**
   * Users that can upload files to the store.
   * Values will be either:
   * - `domain:example.com` to allow all users in the domain to upload files
   * - `user:username@example.com` to allow a specific user to upload files
   *
   * @generated from field: repeated string uploaders = 7;
   */
  uploaders: string[] = [];

  /**
   * Users who can view the store
   * Values will be either:
   * - `domain:example.com` to allow all users in the domain to view the store
   * - `user:user@example.com` to allow a specific user to view the store
   *
   * @generated from field: repeated string viewers = 5;
   */
  viewers: string[] = [];

  /**
   * Timestamp when the store was created
   *
   * @generated from field: google.protobuf.Timestamp create_time = 4;
   */
  createTime?: Timestamp;

  constructor(data?: PartialMessage<Store>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.Store";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "managed_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "owner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "owners", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "uploaders", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "viewers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "create_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Store {
    return new Store().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Store {
    return new Store().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Store {
    return new Store().fromJsonString(jsonString, options);
  }

  static equals(a: Store | PlainMessage<Store> | undefined, b: Store | PlainMessage<Store> | undefined): boolean {
    return proto3.util.equals(Store, a, b);
  }
}

/**
 * Configuration for embedding documents in a store
 *
 * @generated from message ai.EmbeddingConfig
 */
export class EmbeddingConfig extends Message<EmbeddingConfig> {
  /**
   * The model used to generate embeddings for documents in the store.
   *
   * @generated from field: ai.EmbeddingConfig.Model model = 1;
   */
  model = EmbeddingConfig_Model.MODEL_UNSPECIFIED;

  /**
   * The number of dimensions in the embedding space.
   * The constraints of this value are determined by the model.
   * For example, the OpenAI Text Embedding Ada 002 model is always 1536 dimensions.
   *
   * @generated from field: uint32 dimensions = 2;
   */
  dimensions = 0;

  constructor(data?: PartialMessage<EmbeddingConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.EmbeddingConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "model", kind: "enum", T: proto3.getEnumType(EmbeddingConfig_Model) },
    { no: 2, name: "dimensions", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EmbeddingConfig {
    return new EmbeddingConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EmbeddingConfig {
    return new EmbeddingConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EmbeddingConfig {
    return new EmbeddingConfig().fromJsonString(jsonString, options);
  }

  static equals(a: EmbeddingConfig | PlainMessage<EmbeddingConfig> | undefined, b: EmbeddingConfig | PlainMessage<EmbeddingConfig> | undefined): boolean {
    return proto3.util.equals(EmbeddingConfig, a, b);
  }
}

/**
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.EmbeddingConfig.Model
 */
export enum EmbeddingConfig_Model {
  /**
   * @generated from enum value: MODEL_UNSPECIFIED = 0;
   */
  MODEL_UNSPECIFIED = 0,

  /**
   * @generated from enum value: OPENAI_TEXT_EMBEDDING_ADA_002 = 1;
   */
  OPENAI_TEXT_EMBEDDING_ADA_002 = 1,

  /**
   * @generated from enum value: OPENAI_TEXT_EMBEDDING_3_SMALL = 2;
   */
  OPENAI_TEXT_EMBEDDING_3_SMALL = 2,

  /**
   * @generated from enum value: OPENAI_TEXT_EMBEDDING_3_LARGE = 3;
   */
  OPENAI_TEXT_EMBEDDING_3_LARGE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(EmbeddingConfig_Model)
proto3.util.setEnumType(EmbeddingConfig_Model, "ai.EmbeddingConfig.Model", [
  { no: 0, name: "MODEL_UNSPECIFIED" },
  { no: 1, name: "OPENAI_TEXT_EMBEDDING_ADA_002" },
  { no: 2, name: "OPENAI_TEXT_EMBEDDING_3_SMALL" },
  { no: 3, name: "OPENAI_TEXT_EMBEDDING_3_LARGE" },
]);

/**
 * Configuration for chunking documents
 *
 * @generated from message ai.ChunkingConfig
 */
export class ChunkingConfig extends Message<ChunkingConfig> {
  /**
   * The maximum size of a chunk in tokens.
   *
   * @generated from field: uint32 chunk_size = 1;
   */
  chunkSize = 0;

  /**
   * The number of tokens to overlap between chunks.
   *
   * @generated from field: uint32 chunk_overlap = 2;
   */
  chunkOverlap = 0;

  constructor(data?: PartialMessage<ChunkingConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.ChunkingConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chunk_size", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "chunk_overlap", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChunkingConfig {
    return new ChunkingConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChunkingConfig {
    return new ChunkingConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChunkingConfig {
    return new ChunkingConfig().fromJsonString(jsonString, options);
  }

  static equals(a: ChunkingConfig | PlainMessage<ChunkingConfig> | undefined, b: ChunkingConfig | PlainMessage<ChunkingConfig> | undefined): boolean {
    return proto3.util.equals(ChunkingConfig, a, b);
  }
}

/**
 * A piece of store configuration.
 *
 * @generated from message ai.StoreConfig
 */
export class StoreConfig extends Message<StoreConfig> {
  /**
   * Name of the configuration.  Will be empty for top-level configuration.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Config value
   *
   * @generated from field: google.protobuf.Struct config = 2;
   */
  config?: Struct;

  constructor(data?: PartialMessage<StoreConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.StoreConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "config", kind: "message", T: Struct },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StoreConfig {
    return new StoreConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StoreConfig {
    return new StoreConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StoreConfig {
    return new StoreConfig().fromJsonString(jsonString, options);
  }

  static equals(a: StoreConfig | PlainMessage<StoreConfig> | undefined, b: StoreConfig | PlainMessage<StoreConfig> | undefined): boolean {
    return proto3.util.equals(StoreConfig, a, b);
  }
}

/**
 * Request for the `GetStoreConfig` method.
 *
 * @generated from message ai.GetStoreConfigRequest
 */
export class GetStoreConfigRequest extends Message<GetStoreConfigRequest> {
  /**
   * REQUIRED. ID of the store to retrieve the configuration for.
   *
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * OPTIONAL. The configuration to retrieve.
   * Leave blank to retrieve the top-level store configuration.
   *
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<GetStoreConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.GetStoreConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStoreConfigRequest {
    return new GetStoreConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStoreConfigRequest {
    return new GetStoreConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStoreConfigRequest {
    return new GetStoreConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetStoreConfigRequest | PlainMessage<GetStoreConfigRequest> | undefined, b: GetStoreConfigRequest | PlainMessage<GetStoreConfigRequest> | undefined): boolean {
    return proto3.util.equals(GetStoreConfigRequest, a, b);
  }
}

/**
 * A request to create a new store
 *
 * @generated from message ai.CreateStoreRequest
 */
export class CreateStoreRequest extends Message<CreateStoreRequest> {
  /**
   * user-specified id of the store
   * If blank, a id will be generated
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * user-specified display name of the store
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * Chunking configuration
   * If not specified, a default configuration will be used.
   *
   * @generated from field: ai.ChunkingConfig chunking_config = 4;
   */
  chunkingConfig?: ChunkingConfig;

  /**
   * Configuration for generation of vector embeddings. If not specified, a default configuration will be used.
   * Embedding configuration cannot be changed once a store is created.
   *
   * @generated from field: ai.EmbeddingConfig embedding_config = 5;
   */
  embeddingConfig?: EmbeddingConfig;

  /**
   * Users that own the store.
   * When no owners are specified, the user making the request will be the owner.
   * Values must be in the form `user:user@example.com`
   *
   * @generated from field: repeated string owners = 6;
   */
  owners: string[] = [];

  /**
   * Users that are allowed to upload files to the store.
   * Values must be either:
   * - `domain:example.com` to allow all users in the domain to upload to the store
   * - `user:user@example.com` to allow a specific user to upload to the store
   *
   * @generated from field: repeated string uploaders = 7;
   */
  uploaders: string[] = [];

  /**
   * Users who can view the store
   * Values must be either:
   * - `domain:example.com` to allow all users in the domain to view the store
   * - `user:user@example.com` to allow a specific user to view the store
   *
   * @generated from field: repeated string viewers = 3;
   */
  viewers: string[] = [];

  constructor(data?: PartialMessage<CreateStoreRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.CreateStoreRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "chunking_config", kind: "message", T: ChunkingConfig },
    { no: 5, name: "embedding_config", kind: "message", T: EmbeddingConfig },
    { no: 6, name: "owners", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "uploaders", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "viewers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateStoreRequest {
    return new CreateStoreRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateStoreRequest {
    return new CreateStoreRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateStoreRequest {
    return new CreateStoreRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateStoreRequest | PlainMessage<CreateStoreRequest> | undefined, b: CreateStoreRequest | PlainMessage<CreateStoreRequest> | undefined): boolean {
    return proto3.util.equals(CreateStoreRequest, a, b);
  }
}

/**
 * @generated from message ai.GetStoreRequest
 */
export class GetStoreRequest extends Message<GetStoreRequest> {
  /**
   * id of the store to retrieve
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetStoreRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.GetStoreRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStoreRequest {
    return new GetStoreRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStoreRequest {
    return new GetStoreRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStoreRequest {
    return new GetStoreRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetStoreRequest | PlainMessage<GetStoreRequest> | undefined, b: GetStoreRequest | PlainMessage<GetStoreRequest> | undefined): boolean {
    return proto3.util.equals(GetStoreRequest, a, b);
  }
}

/**
 * A request to list available stores
 *
 * TODO: pagination
 *
 * @generated from message ai.ListStoresRequest
 */
export class ListStoresRequest extends Message<ListStoresRequest> {
  constructor(data?: PartialMessage<ListStoresRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.ListStoresRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListStoresRequest {
    return new ListStoresRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListStoresRequest {
    return new ListStoresRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListStoresRequest {
    return new ListStoresRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListStoresRequest | PlainMessage<ListStoresRequest> | undefined, b: ListStoresRequest | PlainMessage<ListStoresRequest> | undefined): boolean {
    return proto3.util.equals(ListStoresRequest, a, b);
  }
}

/**
 * A response containing a list of available stores
 *
 * @generated from message ai.ListStoresResponse
 */
export class ListStoresResponse extends Message<ListStoresResponse> {
  /**
   * list of stores
   *
   * @generated from field: repeated ai.Store stores = 1;
   */
  stores: Store[] = [];

  constructor(data?: PartialMessage<ListStoresResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.ListStoresResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stores", kind: "message", T: Store, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListStoresResponse {
    return new ListStoresResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListStoresResponse {
    return new ListStoresResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListStoresResponse {
    return new ListStoresResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListStoresResponse | PlainMessage<ListStoresResponse> | undefined, b: ListStoresResponse | PlainMessage<ListStoresResponse> | undefined): boolean {
    return proto3.util.equals(ListStoresResponse, a, b);
  }
}

/**
 * A request to update the state of a store
 *
 * @generated from message ai.UpdateStoreRequest
 */
export class UpdateStoreRequest extends Message<UpdateStoreRequest> {
  /**
   * Id of the store to update
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * The display name of the store
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * A list of owners.
   * Values must be in the form `user:username@exmaple.com`
   *
   * @generated from field: ai.type.StringList owners = 4;
   */
  owners?: StringList;

  /**
   * A list of uploaders.
   * Values must be either:
   * - `domain:example.com` to allow all users in the domain to upload to the store
   * - `user:username@example.com` to allow a specific user to upload to the store
   *
   * @generated from field: ai.type.StringList uploaders = 5;
   */
  uploaders?: StringList;

  /**
   * Users who can view the store
   * Values must be either:
   * - `domain:example.com` to allow all users in the domain to view the store
   * - `user:user@example.com` to allow a specific user to view the store
   *
   * @generated from field: ai.UpdateStoreRequest.ViewersList viewers = 3;
   */
  viewers?: UpdateStoreRequest_ViewersList;

  constructor(data?: PartialMessage<UpdateStoreRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.UpdateStoreRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "owners", kind: "message", T: StringList },
    { no: 5, name: "uploaders", kind: "message", T: StringList },
    { no: 3, name: "viewers", kind: "message", T: UpdateStoreRequest_ViewersList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStoreRequest {
    return new UpdateStoreRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStoreRequest {
    return new UpdateStoreRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStoreRequest {
    return new UpdateStoreRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStoreRequest | PlainMessage<UpdateStoreRequest> | undefined, b: UpdateStoreRequest | PlainMessage<UpdateStoreRequest> | undefined): boolean {
    return proto3.util.equals(UpdateStoreRequest, a, b);
  }
}

/**
 * A list of viewers. This is in a wrapper type so that we can allow for partial updates of a store
 * Values must be either:
 * - `domain:example.com` to allow all users in the domain to view the store
 * - `user:user@example.com` to allow a specific user to view the store
 *
 * @generated from message ai.UpdateStoreRequest.ViewersList
 */
export class UpdateStoreRequest_ViewersList extends Message<UpdateStoreRequest_ViewersList> {
  /**
   * List of viewers
   *
   * @generated from field: repeated string values = 1;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<UpdateStoreRequest_ViewersList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.UpdateStoreRequest.ViewersList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStoreRequest_ViewersList {
    return new UpdateStoreRequest_ViewersList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStoreRequest_ViewersList {
    return new UpdateStoreRequest_ViewersList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStoreRequest_ViewersList {
    return new UpdateStoreRequest_ViewersList().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStoreRequest_ViewersList | PlainMessage<UpdateStoreRequest_ViewersList> | undefined, b: UpdateStoreRequest_ViewersList | PlainMessage<UpdateStoreRequest_ViewersList> | undefined): boolean {
    return proto3.util.equals(UpdateStoreRequest_ViewersList, a, b);
  }
}

/**
 * A request to delete a store
 *
 * @generated from message ai.DeleteStoreRequest
 */
export class DeleteStoreRequest extends Message<DeleteStoreRequest> {
  /**
   * id of the store to delete
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteStoreRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.DeleteStoreRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteStoreRequest {
    return new DeleteStoreRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteStoreRequest {
    return new DeleteStoreRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteStoreRequest {
    return new DeleteStoreRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteStoreRequest | PlainMessage<DeleteStoreRequest> | undefined, b: DeleteStoreRequest | PlainMessage<DeleteStoreRequest> | undefined): boolean {
    return proto3.util.equals(DeleteStoreRequest, a, b);
  }
}

/**
 * An indexed file within a store.
 *
 * @generated from message ai.File
 */
export class File extends Message<File> {
  /**
   * id of the store containing the file
   *
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * name of the fil3
   *
   * @generated from field: string filename = 2;
   */
  filename = "";

  /**
   * size of the file in bytes
   *
   * @generated from field: int64 size = 3;
   */
  size = protoInt64.zero;

  /**
   * timestamp when the file was uploaded
   *
   * @generated from field: google.protobuf.Timestamp upload_time = 4;
   */
  uploadTime?: Timestamp;

  /**
   * GCS signed link where file can be downloaded
   *
   * @generated from field: string url = 5;
   */
  url = "";

  /**
   * The current state of the file
   *
   * @generated from field: ai.File.State state = 6;
   */
  state = File_State.STATE_UNSPECIFIED;

  /**
   * A message describing the current state of the file
   *
   * @generated from field: string state_reason = 7;
   */
  stateReason = "";

  constructor(data?: PartialMessage<File>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.File";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "upload_time", kind: "message", T: Timestamp },
    { no: 5, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "state", kind: "enum", T: proto3.getEnumType(File_State) },
    { no: 7, name: "state_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): File {
    return new File().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): File {
    return new File().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): File {
    return new File().fromJsonString(jsonString, options);
  }

  static equals(a: File | PlainMessage<File> | undefined, b: File | PlainMessage<File> | undefined): boolean {
    return proto3.util.equals(File, a, b);
  }
}

/**
 * State of the file
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.File.State
 */
export enum File_State {
  /**
   * @generated from enum value: STATE_UNSPECIFIED = 0;
   */
  STATE_UNSPECIFIED = 0,

  /**
   * The file is currently being indexing
   *
   * @generated from enum value: INDEXING = 1;
   */
  INDEXING = 1,

  /**
   * The file is currently being deleted
   *
   * @generated from enum value: DELETING = 4;
   */
  DELETING = 4,

  /**
   * The file has been successfully indexed
   *
   * @generated from enum value: INDEXED = 2;
   */
  INDEXED = 2,

  /**
   * The file has failed to index
   * Reference the state_reason field for more information
   *
   * @generated from enum value: FAILED = 3;
   */
  FAILED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(File_State)
proto3.util.setEnumType(File_State, "ai.File.State", [
  { no: 0, name: "STATE_UNSPECIFIED" },
  { no: 1, name: "INDEXING" },
  { no: 4, name: "DELETING" },
  { no: 2, name: "INDEXED" },
  { no: 3, name: "FAILED" },
]);

/**
 * A request to upload and index a file in a store.
 *
 * @generated from message ai.UploadFileRequest
 */
export class UploadFileRequest extends Message<UploadFileRequest> {
  /**
   * id of the store
   *
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * name of the file
   *
   * @generated from field: string filename = 2;
   */
  filename = "";

  /**
   * User-specified content type of the file.
   * If no value is provided, the server will attempt to guess the content type using the extension of the file. Unknown
   * extensions will default to application/octet-stream.
   *
   * @generated from field: string content_type = 3;
   */
  contentType = "";

  constructor(data?: PartialMessage<UploadFileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.UploadFileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadFileRequest {
    return new UploadFileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadFileRequest {
    return new UploadFileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadFileRequest {
    return new UploadFileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadFileRequest | PlainMessage<UploadFileRequest> | undefined, b: UploadFileRequest | PlainMessage<UploadFileRequest> | undefined): boolean {
    return proto3.util.equals(UploadFileRequest, a, b);
  }
}

/**
 * Response to a unary upload file request
 *
 * @generated from message ai.UploadFileUnaryResponse
 */
export class UploadFileUnaryResponse extends Message<UploadFileUnaryResponse> {
  /**
   * id of the store
   *
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * name of the file
   *
   * @generated from field: string filename = 2;
   */
  filename = "";

  /**
   * GCS signed link where file can be uploaded
   * Client should call this URL with an HTTP PUT and include the contents of the file as the body of the request.
   *
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * The set of headers that MUST be included with the HTTP request to the upload url
   *
   * @generated from field: map<string, string> headers = 4;
   */
  headers: { [key: string]: string } = {};

  constructor(data?: PartialMessage<UploadFileUnaryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.UploadFileUnaryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "headers", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadFileUnaryResponse {
    return new UploadFileUnaryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadFileUnaryResponse {
    return new UploadFileUnaryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadFileUnaryResponse {
    return new UploadFileUnaryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UploadFileUnaryResponse | PlainMessage<UploadFileUnaryResponse> | undefined, b: UploadFileUnaryResponse | PlainMessage<UploadFileUnaryResponse> | undefined): boolean {
    return proto3.util.equals(UploadFileUnaryResponse, a, b);
  }
}

/**
 * An event in the file upload process.
 *
 * @generated from message ai.UploadFileEvent
 */
export class UploadFileEvent extends Message<UploadFileEvent> {
  /**
   * id of the store
   *
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * name of the file
   *
   * @generated from field: string filename = 2;
   */
  filename = "";

  /**
   * Payload of the event
   *
   * @generated from oneof ai.UploadFileEvent.event
   */
  event: {
    /**
     * @generated from field: ai.UploadFileEvent.Allocated allocated = 3;
     */
    value: UploadFileEvent_Allocated;
    case: "allocated";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UploadFileEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.UploadFileEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "allocated", kind: "message", T: UploadFileEvent_Allocated, oneof: "event" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadFileEvent {
    return new UploadFileEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadFileEvent {
    return new UploadFileEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadFileEvent {
    return new UploadFileEvent().fromJsonString(jsonString, options);
  }

  static equals(a: UploadFileEvent | PlainMessage<UploadFileEvent> | undefined, b: UploadFileEvent | PlainMessage<UploadFileEvent> | undefined): boolean {
    return proto3.util.equals(UploadFileEvent, a, b);
  }
}

/**
 * An event payload indicating that storage for a file has been allocated.
 * Clients should use the provided URL to upload the file.
 *
 * @generated from message ai.UploadFileEvent.Allocated
 */
export class UploadFileEvent_Allocated extends Message<UploadFileEvent_Allocated> {
  /**
   * GCS signed link where file can be uploaded
   *
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * The set of headers that MUST be included with the HTTP request to the upload url
   *
   * @generated from field: map<string, string> headers = 2;
   */
  headers: { [key: string]: string } = {};

  constructor(data?: PartialMessage<UploadFileEvent_Allocated>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.UploadFileEvent.Allocated";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "headers", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadFileEvent_Allocated {
    return new UploadFileEvent_Allocated().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadFileEvent_Allocated {
    return new UploadFileEvent_Allocated().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadFileEvent_Allocated {
    return new UploadFileEvent_Allocated().fromJsonString(jsonString, options);
  }

  static equals(a: UploadFileEvent_Allocated | PlainMessage<UploadFileEvent_Allocated> | undefined, b: UploadFileEvent_Allocated | PlainMessage<UploadFileEvent_Allocated> | undefined): boolean {
    return proto3.util.equals(UploadFileEvent_Allocated, a, b);
  }
}

/**
 * A request to get the current state of a file in a store
 *
 * @generated from message ai.GetFileRequest
 */
export class GetFileRequest extends Message<GetFileRequest> {
  /**
   * id of the store
   *
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * name of the file
   *
   * @generated from field: string filename = 2;
   */
  filename = "";

  constructor(data?: PartialMessage<GetFileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.GetFileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFileRequest {
    return new GetFileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFileRequest {
    return new GetFileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFileRequest {
    return new GetFileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFileRequest | PlainMessage<GetFileRequest> | undefined, b: GetFileRequest | PlainMessage<GetFileRequest> | undefined): boolean {
    return proto3.util.equals(GetFileRequest, a, b);
  }
}

/**
 * A request to list the files in a store
 *
 * @generated from message ai.ListFilesRequest
 */
export class ListFilesRequest extends Message<ListFilesRequest> {
  /**
   * id of the store
   *
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  constructor(data?: PartialMessage<ListFilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.ListFilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFilesRequest {
    return new ListFilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFilesRequest {
    return new ListFilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFilesRequest {
    return new ListFilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListFilesRequest | PlainMessage<ListFilesRequest> | undefined, b: ListFilesRequest | PlainMessage<ListFilesRequest> | undefined): boolean {
    return proto3.util.equals(ListFilesRequest, a, b);
  }
}

/**
 * A response containing a list of files in a store
 *
 * @generated from message ai.ListFilesResponse
 */
export class ListFilesResponse extends Message<ListFilesResponse> {
  /**
   * list of files in the store
   *
   * @generated from field: repeated ai.File files = 1;
   */
  files: File[] = [];

  constructor(data?: PartialMessage<ListFilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.ListFilesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "files", kind: "message", T: File, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFilesResponse {
    return new ListFilesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFilesResponse {
    return new ListFilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFilesResponse {
    return new ListFilesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListFilesResponse | PlainMessage<ListFilesResponse> | undefined, b: ListFilesResponse | PlainMessage<ListFilesResponse> | undefined): boolean {
    return proto3.util.equals(ListFilesResponse, a, b);
  }
}

/**
 * A request to delete a file from a store
 *
 * @generated from message ai.DeleteFileRequest
 */
export class DeleteFileRequest extends Message<DeleteFileRequest> {
  /**
   * id of the store
   *
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * name of the file
   *
   * @generated from field: string filename = 2;
   */
  filename = "";

  constructor(data?: PartialMessage<DeleteFileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.DeleteFileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteFileRequest {
    return new DeleteFileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteFileRequest {
    return new DeleteFileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteFileRequest {
    return new DeleteFileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteFileRequest | PlainMessage<DeleteFileRequest> | undefined, b: DeleteFileRequest | PlainMessage<DeleteFileRequest> | undefined): boolean {
    return proto3.util.equals(DeleteFileRequest, a, b);
  }
}

/**
 * Request message for `TestStorePermissions` method.
 *
 * @generated from message ai.TestStorePermissionsRequest
 */
export class TestStorePermissionsRequest extends Message<TestStorePermissionsRequest> {
  /**
   * REQUIRED: THe ID of the Store to test permissions for.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * REQUIRED: The set of permissions to test.
   * Currently supported permissions are:
   * - `files.get`
   * - `files.list`
   * - `files.upload`
   * - `files.delete`
   * - `stores.get`
   * - `stores.update`
   * - `stores.delete`
   *
   * @generated from field: repeated string permissions = 2;
   */
  permissions: string[] = [];

  constructor(data?: PartialMessage<TestStorePermissionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.TestStorePermissionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "permissions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TestStorePermissionsRequest {
    return new TestStorePermissionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TestStorePermissionsRequest {
    return new TestStorePermissionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TestStorePermissionsRequest {
    return new TestStorePermissionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TestStorePermissionsRequest | PlainMessage<TestStorePermissionsRequest> | undefined, b: TestStorePermissionsRequest | PlainMessage<TestStorePermissionsRequest> | undefined): boolean {
    return proto3.util.equals(TestStorePermissionsRequest, a, b);
  }
}

/**
 * Response message for `TestStorePermissions` method.
 *
 * @generated from message ai.TestStorePermissionsResponse
 */
export class TestStorePermissionsResponse extends Message<TestStorePermissionsResponse> {
  /**
   * The set of permission the user has on the Store.
   *
   * @generated from field: repeated string permissions = 1;
   */
  permissions: string[] = [];

  constructor(data?: PartialMessage<TestStorePermissionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.TestStorePermissionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "permissions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TestStorePermissionsResponse {
    return new TestStorePermissionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TestStorePermissionsResponse {
    return new TestStorePermissionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TestStorePermissionsResponse {
    return new TestStorePermissionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TestStorePermissionsResponse | PlainMessage<TestStorePermissionsResponse> | undefined, b: TestStorePermissionsResponse | PlainMessage<TestStorePermissionsResponse> | undefined): boolean {
    return proto3.util.equals(TestStorePermissionsResponse, a, b);
  }
}

/**
 * FileIndexEvent is an event indicating that a file has been indexed or deleted
 *
 * @generated from message ai.FileIndexEvent
 */
export class FileIndexEvent extends Message<FileIndexEvent> {
  /**
   * id of the event
   *
   * @generated from field: string event_id = 1;
   */
  eventId = "";

  /**
   * type of the event
   *
   * @generated from field: ai.FileIndexEvent.EventType event_type = 2;
   */
  eventType = FileIndexEvent_EventType.EVENT_TYPE_UNSPECIFIED;

  /**
   * timestamp of the event
   *
   * @generated from field: google.protobuf.Timestamp event_time = 3;
   */
  eventTime?: Timestamp;

  /**
   * data of the event
   *
   * @generated from field: ai.FileIndexEvent.EventData data = 4;
   */
  data?: FileIndexEvent_EventData;

  constructor(data?: PartialMessage<FileIndexEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.FileIndexEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "event_type", kind: "enum", T: proto3.getEnumType(FileIndexEvent_EventType) },
    { no: 3, name: "event_time", kind: "message", T: Timestamp },
    { no: 4, name: "data", kind: "message", T: FileIndexEvent_EventData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileIndexEvent {
    return new FileIndexEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileIndexEvent {
    return new FileIndexEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileIndexEvent {
    return new FileIndexEvent().fromJsonString(jsonString, options);
  }

  static equals(a: FileIndexEvent | PlainMessage<FileIndexEvent> | undefined, b: FileIndexEvent | PlainMessage<FileIndexEvent> | undefined): boolean {
    return proto3.util.equals(FileIndexEvent, a, b);
  }
}

/**
 * Type of the event
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.FileIndexEvent.EventType
 */
export enum FileIndexEvent_EventType {
  /**
   * @generated from enum value: EVENT_TYPE_UNSPECIFIED = 0;
   */
  EVENT_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: INDEXING_STARTED = 3;
   */
  INDEXING_STARTED = 3,

  /**
   * @generated from enum value: INDEXED = 1;
   */
  INDEXED = 1,

  /**
   * @generated from enum value: DELETED = 2;
   */
  DELETED = 2,

  /**
   * @generated from enum value: FAILED = 4;
   */
  FAILED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(FileIndexEvent_EventType)
proto3.util.setEnumType(FileIndexEvent_EventType, "ai.FileIndexEvent.EventType", [
  { no: 0, name: "EVENT_TYPE_UNSPECIFIED" },
  { no: 3, name: "INDEXING_STARTED" },
  { no: 1, name: "INDEXED" },
  { no: 2, name: "DELETED" },
  { no: 4, name: "FAILED" },
]);

/**
 * @generated from message ai.FileIndexEvent.EventData
 */
export class FileIndexEvent_EventData extends Message<FileIndexEvent_EventData> {
  /**
   * id of the store
   *
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * name of the file
   *
   * @generated from field: string filename = 2;
   */
  filename = "";

  /**
   * size of the file in bytes
   *
   * @generated from field: uint64 size = 3;
   */
  size = protoInt64.zero;

  /**
   * md5 hash of the file
   *
   * @generated from field: string md5 = 4;
   */
  md5 = "";

  /**
   * timestamp when the file was created in GCS
   *
   * @generated from field: google.protobuf.Timestamp create_time = 5;
   */
  createTime?: Timestamp;

  /**
   * reason for the event
   *
   * @generated from field: string reason = 6;
   */
  reason = "";

  constructor(data?: PartialMessage<FileIndexEvent_EventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.FileIndexEvent.EventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "md5", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "create_time", kind: "message", T: Timestamp },
    { no: 6, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileIndexEvent_EventData {
    return new FileIndexEvent_EventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileIndexEvent_EventData {
    return new FileIndexEvent_EventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileIndexEvent_EventData {
    return new FileIndexEvent_EventData().fromJsonString(jsonString, options);
  }

  static equals(a: FileIndexEvent_EventData | PlainMessage<FileIndexEvent_EventData> | undefined, b: FileIndexEvent_EventData | PlainMessage<FileIndexEvent_EventData> | undefined): boolean {
    return proto3.util.equals(FileIndexEvent_EventData, a, b);
  }
}

/**
 * @generated from message ai.IndexJobEvent
 */
export class IndexJobEvent extends Message<IndexJobEvent> {
  /**
   * @generated from field: string event_id = 1;
   */
  eventId = "";

  /**
   * @generated from field: google.protobuf.Timestamp event_time = 3;
   */
  eventTime?: Timestamp;

  /**
   * @generated from oneof ai.IndexJobEvent.data
   */
  data: {
    /**
     * @generated from field: ai.IndexJobEvent.JobRequested job_requested = 6;
     */
    value: IndexJobEvent_JobRequested;
    case: "jobRequested";
  } | {
    /**
     * @generated from field: ai.IndexJobEvent.JobStarted job_started = 7;
     */
    value: IndexJobEvent_JobStarted;
    case: "jobStarted";
  } | {
    /**
     * @generated from field: ai.IndexJobEvent.JobSucceeded job_succeeded = 8;
     */
    value: IndexJobEvent_JobSucceeded;
    case: "jobSucceeded";
  } | {
    /**
     * @generated from field: ai.IndexJobEvent.JobFailed job_failed = 9;
     */
    value: IndexJobEvent_JobFailed;
    case: "jobFailed";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<IndexJobEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.IndexJobEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "event_time", kind: "message", T: Timestamp },
    { no: 6, name: "job_requested", kind: "message", T: IndexJobEvent_JobRequested, oneof: "data" },
    { no: 7, name: "job_started", kind: "message", T: IndexJobEvent_JobStarted, oneof: "data" },
    { no: 8, name: "job_succeeded", kind: "message", T: IndexJobEvent_JobSucceeded, oneof: "data" },
    { no: 9, name: "job_failed", kind: "message", T: IndexJobEvent_JobFailed, oneof: "data" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndexJobEvent {
    return new IndexJobEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndexJobEvent {
    return new IndexJobEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndexJobEvent {
    return new IndexJobEvent().fromJsonString(jsonString, options);
  }

  static equals(a: IndexJobEvent | PlainMessage<IndexJobEvent> | undefined, b: IndexJobEvent | PlainMessage<IndexJobEvent> | undefined): boolean {
    return proto3.util.equals(IndexJobEvent, a, b);
  }
}

/**
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.IndexJobEvent.JobAction
 */
export enum IndexJobEvent_JobAction {
  /**
   * @generated from enum value: JOB_ACTION_UNSPECIFIED = 0;
   */
  JOB_ACTION_UNSPECIFIED = 0,

  /**
   * @generated from enum value: INSERT = 1;
   */
  INSERT = 1,

  /**
   * @generated from enum value: DELETE = 2;
   */
  DELETE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(IndexJobEvent_JobAction)
proto3.util.setEnumType(IndexJobEvent_JobAction, "ai.IndexJobEvent.JobAction", [
  { no: 0, name: "JOB_ACTION_UNSPECIFIED" },
  { no: 1, name: "INSERT" },
  { no: 2, name: "DELETE" },
]);

/**
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.IndexJobEvent.JobState
 */
export enum IndexJobEvent_JobState {
  /**
   * @generated from enum value: JOB_STATE_UNSPECIFIED = 0;
   */
  JOB_STATE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: RUNNING = 2;
   */
  RUNNING = 2,

  /**
   * @generated from enum value: COMPLETED = 3;
   */
  COMPLETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(IndexJobEvent_JobState)
proto3.util.setEnumType(IndexJobEvent_JobState, "ai.IndexJobEvent.JobState", [
  { no: 0, name: "JOB_STATE_UNSPECIFIED" },
  { no: 1, name: "PENDING" },
  { no: 2, name: "RUNNING" },
  { no: 3, name: "COMPLETED" },
]);

/**
 * @generated from message ai.IndexJobEvent.File
 */
export class IndexJobEvent_File extends Message<IndexJobEvent_File> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string md5 = 2;
   */
  md5 = "";

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 3;
   */
  createTime?: Timestamp;

  constructor(data?: PartialMessage<IndexJobEvent_File>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.IndexJobEvent.File";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "md5", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "create_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndexJobEvent_File {
    return new IndexJobEvent_File().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndexJobEvent_File {
    return new IndexJobEvent_File().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndexJobEvent_File {
    return new IndexJobEvent_File().fromJsonString(jsonString, options);
  }

  static equals(a: IndexJobEvent_File | PlainMessage<IndexJobEvent_File> | undefined, b: IndexJobEvent_File | PlainMessage<IndexJobEvent_File> | undefined): boolean {
    return proto3.util.equals(IndexJobEvent_File, a, b);
  }
}

/**
 * @generated from message ai.IndexJobEvent.Job
 */
export class IndexJobEvent_Job extends Message<IndexJobEvent_Job> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string batch_id = 2;
   */
  batchId = "";

  /**
   * @generated from field: ai.IndexJobEvent.JobAction action = 3;
   */
  action = IndexJobEvent_JobAction.JOB_ACTION_UNSPECIFIED;

  /**
   * @generated from field: string exec_name = 4;
   */
  execName = "";

  constructor(data?: PartialMessage<IndexJobEvent_Job>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.IndexJobEvent.Job";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "batch_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "action", kind: "enum", T: proto3.getEnumType(IndexJobEvent_JobAction) },
    { no: 4, name: "exec_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndexJobEvent_Job {
    return new IndexJobEvent_Job().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndexJobEvent_Job {
    return new IndexJobEvent_Job().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndexJobEvent_Job {
    return new IndexJobEvent_Job().fromJsonString(jsonString, options);
  }

  static equals(a: IndexJobEvent_Job | PlainMessage<IndexJobEvent_Job> | undefined, b: IndexJobEvent_Job | PlainMessage<IndexJobEvent_Job> | undefined): boolean {
    return proto3.util.equals(IndexJobEvent_Job, a, b);
  }
}

/**
 * @generated from message ai.IndexJobEvent.JobRequested
 */
export class IndexJobEvent_JobRequested extends Message<IndexJobEvent_JobRequested> {
  /**
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * @generated from field: ai.IndexJobEvent.File file = 2;
   */
  file?: IndexJobEvent_File;

  /**
   * @generated from field: ai.IndexJobEvent.Job job = 3;
   */
  job?: IndexJobEvent_Job;

  constructor(data?: PartialMessage<IndexJobEvent_JobRequested>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.IndexJobEvent.JobRequested";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file", kind: "message", T: IndexJobEvent_File },
    { no: 3, name: "job", kind: "message", T: IndexJobEvent_Job },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndexJobEvent_JobRequested {
    return new IndexJobEvent_JobRequested().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndexJobEvent_JobRequested {
    return new IndexJobEvent_JobRequested().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndexJobEvent_JobRequested {
    return new IndexJobEvent_JobRequested().fromJsonString(jsonString, options);
  }

  static equals(a: IndexJobEvent_JobRequested | PlainMessage<IndexJobEvent_JobRequested> | undefined, b: IndexJobEvent_JobRequested | PlainMessage<IndexJobEvent_JobRequested> | undefined): boolean {
    return proto3.util.equals(IndexJobEvent_JobRequested, a, b);
  }
}

/**
 * @generated from message ai.IndexJobEvent.JobStarted
 */
export class IndexJobEvent_JobStarted extends Message<IndexJobEvent_JobStarted> {
  /**
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * @generated from field: ai.IndexJobEvent.File file = 2;
   */
  file?: IndexJobEvent_File;

  /**
   * @generated from field: ai.IndexJobEvent.Job job = 3;
   */
  job?: IndexJobEvent_Job;

  constructor(data?: PartialMessage<IndexJobEvent_JobStarted>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.IndexJobEvent.JobStarted";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file", kind: "message", T: IndexJobEvent_File },
    { no: 3, name: "job", kind: "message", T: IndexJobEvent_Job },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndexJobEvent_JobStarted {
    return new IndexJobEvent_JobStarted().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndexJobEvent_JobStarted {
    return new IndexJobEvent_JobStarted().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndexJobEvent_JobStarted {
    return new IndexJobEvent_JobStarted().fromJsonString(jsonString, options);
  }

  static equals(a: IndexJobEvent_JobStarted | PlainMessage<IndexJobEvent_JobStarted> | undefined, b: IndexJobEvent_JobStarted | PlainMessage<IndexJobEvent_JobStarted> | undefined): boolean {
    return proto3.util.equals(IndexJobEvent_JobStarted, a, b);
  }
}

/**
 * @generated from message ai.IndexJobEvent.JobSucceeded
 */
export class IndexJobEvent_JobSucceeded extends Message<IndexJobEvent_JobSucceeded> {
  /**
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * @generated from field: ai.IndexJobEvent.File file = 2;
   */
  file?: IndexJobEvent_File;

  /**
   * @generated from field: ai.IndexJobEvent.Job job = 3;
   */
  job?: IndexJobEvent_Job;

  /**
   * @generated from field: uint32 n_inserted = 4;
   */
  nInserted = 0;

  /**
   * @generated from field: uint32 n_deleted = 5;
   */
  nDeleted = 0;

  constructor(data?: PartialMessage<IndexJobEvent_JobSucceeded>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.IndexJobEvent.JobSucceeded";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file", kind: "message", T: IndexJobEvent_File },
    { no: 3, name: "job", kind: "message", T: IndexJobEvent_Job },
    { no: 4, name: "n_inserted", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "n_deleted", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndexJobEvent_JobSucceeded {
    return new IndexJobEvent_JobSucceeded().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndexJobEvent_JobSucceeded {
    return new IndexJobEvent_JobSucceeded().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndexJobEvent_JobSucceeded {
    return new IndexJobEvent_JobSucceeded().fromJsonString(jsonString, options);
  }

  static equals(a: IndexJobEvent_JobSucceeded | PlainMessage<IndexJobEvent_JobSucceeded> | undefined, b: IndexJobEvent_JobSucceeded | PlainMessage<IndexJobEvent_JobSucceeded> | undefined): boolean {
    return proto3.util.equals(IndexJobEvent_JobSucceeded, a, b);
  }
}

/**
 * @generated from message ai.IndexJobEvent.JobFailed
 */
export class IndexJobEvent_JobFailed extends Message<IndexJobEvent_JobFailed> {
  /**
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * @generated from field: ai.IndexJobEvent.File file = 2;
   */
  file?: IndexJobEvent_File;

  /**
   * @generated from field: ai.IndexJobEvent.Job job = 3;
   */
  job?: IndexJobEvent_Job;

  /**
   * @generated from field: string failure_reason = 4;
   */
  failureReason = "";

  constructor(data?: PartialMessage<IndexJobEvent_JobFailed>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.IndexJobEvent.JobFailed";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file", kind: "message", T: IndexJobEvent_File },
    { no: 3, name: "job", kind: "message", T: IndexJobEvent_Job },
    { no: 4, name: "failure_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndexJobEvent_JobFailed {
    return new IndexJobEvent_JobFailed().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndexJobEvent_JobFailed {
    return new IndexJobEvent_JobFailed().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndexJobEvent_JobFailed {
    return new IndexJobEvent_JobFailed().fromJsonString(jsonString, options);
  }

  static equals(a: IndexJobEvent_JobFailed | PlainMessage<IndexJobEvent_JobFailed> | undefined, b: IndexJobEvent_JobFailed | PlainMessage<IndexJobEvent_JobFailed> | undefined): boolean {
    return proto3.util.equals(IndexJobEvent_JobFailed, a, b);
  }
}

