import React from "react";
import { useParams } from "react-router-dom";
import ChatUI from "../components/ChatUI/ChatUI";


type ShareChatPageParams = {
  threadId: string;
}

const ShareChat: React.FC = () => {
  var { threadId } = useParams<ShareChatPageParams>();

  return (
    <ChatUI
      threadId={threadId}
      key={threadId}
      isShared
    />
  );
};

export default ShareChat;