import React, { useEffect, useState } from "react";
import {
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import { useAssistantsDialog } from "../AssistantsDialog";
import { MdAdd, MdDelete, MdMoreHoriz } from "react-icons/md";
import { useAssistants } from "../../state/assistants";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Assistant } from "../../gen-ts/ai/assistants/v0/assistant_pb";
import ToastMessage from "../ToastMessage";


interface Props {
  assistant: Assistant;
  onUpdate: (assistant: Assistant) => void;
  disabled: boolean;
}

const TabAssistantAdvanced: React.FC<Props> = ({ assistant, onUpdate, disabled }) => {
  const [ data, setData ] = useState<Assistant>(assistant);
  const assistantsState = useAssistants();
  const toast = useToast();

  useEffect(() => {
    if (assistant) {
      setData(assistant.clone());
      for (const subAssistant of assistant.subAssistants) {
        assistantsState.loadById({
          assistantId: subAssistant,
          onError: () => {
            toast({
              position: 'top-right',
              render: (props) => {
                return (
                  <ToastMessage
                    message={`Assistant: ${subAssistant} not found.`}
                    status="error"
                    isCLosable={props.isClosable}
                    onClose={props.onClose}
                  />
                );
              },
              duration: 2000,
              isClosable: true,
            });
          }
        });
      }
    }
    //eslint-disable-next-line
  }, [ assistant ]);

  const assistantsDialog = useAssistantsDialog();
  const assistants = useAssistants();

  const onOpen = () => {
    assistantsDialog.open({
      title: 'Select Sub Assistant',
      unselectableIds: data.subAssistants,
      onSelect: (assistantId) => {
        data.subAssistants.push(assistantId);
        setData(data.clone());
        onUpdate(data.clone());
      }
    });
  }

  const deleteSubAssistant = (id: string) => {
    data.subAssistants = data.subAssistants.filter((assistantId) => assistantId !== id);
    setData(data.clone());
    onUpdate(data.clone());
  }

  const subAssistants = data.subAssistants ?? [];

  return (
    <VStack bgColor={useColorModeValue('gray.100', 'gray.700')} p={4} borderRadius={10} align="start">
      <HStack width="100%" mb={3}>
        <Text flexGrow={1} fontWeight="600">Sub Assistants</Text>
        <Text>Enable as tools</Text>
        <Switch
          isDisabled={disabled}
          size='lg'
          colorScheme="primary"
          mr={3}
          isChecked={data.subAssistantsAsTools}
          onChange={(e) => {
            data.subAssistantsAsTools = e.target.checked;
            setData(data.clone());
            onUpdate(data.clone());
          }}
        />
        <IconButton
          icon={<MdAdd />}
          aria-label="add new sub assistant"
          colorScheme="primary"
          color="black"
          onClick={onOpen}
          isDisabled={disabled}
        />
      </HStack>

      <TableContainer bgColor={useColorModeValue('white', 'gray.800')} width="100%" borderRadius={10} whiteSpace="wrap">
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Assistant</Th>
              <Th>ID</Th>
              <Th></Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              subAssistants.map((subAssistantId, index) => {
                subAssistantId = subAssistantId.trim();
                const assistant = assistants.assistants[ subAssistantId ];
                return (
                  <Tr key={index}>
                    <Td whiteSpace="nowrap">{assistant?.displayName}</Td>
                    <Td>{assistant?.id}</Td>
                    <Td>
                      <Popover placement="top-end">
                        <PopoverTrigger>
                          <IconButton aria-label="description" variant="ghost" sx={{ borderRadius: 99 }}>
                            <MdMoreHoriz />
                          </IconButton>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverBody>{assistant?.description}</PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Td>
                    <Td>
                      <Button
                        rightIcon={<ExternalLinkIcon />}
                        as={'a'}
                        href={`/assistants/${subAssistantId}`}
                        target="_blank"
                        variant="outline">
                        Edit</Button>
                    </Td>
                    <Td>
                      <IconButton
                        disabled={disabled}
                        aria-label="delete sub assistant"
                        icon={<MdDelete />}
                        onClick={() => deleteSubAssistant(subAssistantId)}
                        variant="ghost"
                      />
                    </Td>
                  </Tr>
                );
              })
            }
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};

export default TabAssistantAdvanced;