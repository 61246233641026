import { createConnectTransport } from "@connectrpc/connect-web";
import { useUserState } from "./state/user"
import { config } from "./config";
import { createPromiseClient, Interceptor } from "@connectrpc/connect";
import { Assistants } from "./gen-ts/ai/assistants/v0/assistant_connect";
import { SlackConfig } from "./gen-ts/slack/slack_config_connect";
import { UserPreferences } from "./gen-ts/user/preferences/v0/preferences_connect";
import axios from "axios";
import { Stores as LegacyStore } from "./gen-ts/ai/stores_connect";
import { Stores } from "./gen-ts/ai/rag/v0/stores_connect";
import { getAccessToken } from "./utils/authTokenHelper";
// import { Stores } from "./gen-ts/ai/rag/v0/stores_connect";


const authTokenRefresher: Interceptor = (next) => async (req) => {
  req.header.set("Authorization", `Bearer ${await getAccessToken()}`);
  return next(req);
};


export const getApiClient = () => {
  var authToken = useUserState.getState().authToken;

  const headers = { Authorization: `Bearer ${authToken}` };
  const transport = createConnectTransport({
    baseUrl: config.grpcBackendUrl,
    interceptors: [ authTokenRefresher ],
  });
  const client = createPromiseClient(Assistants, transport);

  return {
    client,
    headers,
  }
}


export const getSlackApiClient = () => {
  var authToken = useUserState.getState().authToken;

  const headers = { Authorization: `Bearer ${authToken}` };
  const transport = createConnectTransport({
    baseUrl: config.grpcBackendUrl,
    interceptors: [ authTokenRefresher ],
  });
  const client = createPromiseClient(SlackConfig, transport);

  return {
    client,
    headers,
  }
}

export const getUserPreferencesApiClient = () => {
  var authToken = useUserState.getState().authToken;

  const headers = { Authorization: `Bearer ${authToken}` };
  const transport = createConnectTransport({
    baseUrl: config.grpcBackendUrl,
    interceptors: [ authTokenRefresher ],
  });
  const client = createPromiseClient(UserPreferences, transport);

  return {
    client,
    headers,
  }
}

export const getFileListClient = () => {
  var authToken = useUserState.getState().authToken;

  const headers = { Authorization: `Bearer ${authToken}` };
  const transport = createConnectTransport({
    baseUrl: config.grpcBackendUrl,
    interceptors: [ authTokenRefresher ],
  });
  const client = createPromiseClient(Stores, transport);

  return {
    client,
    headers,
  }
}

export const getStoreClient = () => {
  var authToken = useUserState.getState().authToken;

  const headers = { Authorization: `Bearer ${authToken}` };
  const transport = createConnectTransport({
    baseUrl: config.grpcBackendUrl,
    interceptors: [ authTokenRefresher ],
  });
  const client = createPromiseClient(LegacyStore, transport);

  return {
    client,
    headers,
  }
}

export const getChatFilesApi = () => {
  const axiosInstance  = axios.create({
    baseURL: config.grpcBackendUrl,
    headers: {
      "Content-type": "application/json",
    },
  });

  axiosInstance.interceptors.request.use(
    async config => {
      const token = await getAccessToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
  


  return {
    client: axiosInstance,
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${useUserState.getState().authToken}`,
    },
  }
}
