import React, { useEffect, useState } from "react";

import AppPage from "../AppFrame/AppPage";
import AppPageHeader from "../AppFrame/AppPageHeader";
import { Box, Button, Flex, HStack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { GetThreadRequest } from "../../gen-ts/ai/assistants/v0/assistant_pb";
import { useAssistants } from "../../state/assistants";
import ChatInput from "./ChatInput";
import { useChats } from "../../state/chats";
import { getApiClient } from "../../api";
import ChatMessages from "./ChatMessages";
import ExistingChatAssistantButton from "./ExistingChatAssistantButton";
import { OnSendMessageArgs } from "./ChatUI";
import { useNavigate } from "react-router-dom";
import { DEFAULT_ASSISTANT_ID } from "../../config";


interface Props {
  threadId: string;
  onSendMessage: (args: OnSendMessageArgs) => void;
  messagesRef: React.MutableRefObject<HTMLDivElement | null>,
  continueChat: boolean;
}


const SharedChat: React.FC<Props> = ({ threadId, onSendMessage, messagesRef, continueChat }) => {
  const chatsState = useChats();
  const chat = chatsState.chats[ threadId ];

  const navigate = useNavigate();
  const assistantsState = useAssistants();
  const assistantId = DEFAULT_ASSISTANT_ID;
  const assistant = assistantsState.assistants[ assistantId ];

  const topbarBorderColor = useColorModeValue('gray.200', 'gray.700');

  

  const [ pending, setPending ] = useState(true);
  const [ chatTitle, setChatTitle ] = useState<string>('');

  useEffect(() => {
    loadSharedChat();

    // eslint-disable-next-line
  }, [ threadId ]);

  const loadSharedChat = async () => {
    try {
      const { client, headers } = getApiClient();
      const thread = await client.getThread(new GetThreadRequest({ id: threadId }), { headers });
      setChatTitle(thread.description);
    } catch (e) {
      console.error(e);
    }
    setPending(false);
  }

  const startNewChat = (args: OnSendMessageArgs) => {
    onSendMessage({
      ...args,
      messageHistory: chat?.messages.map(m => {
        if (m.content[0].content.case === 'text' && !m.content[0].content.value) {
          m.content[0].content.value= 'empty user message';
        }
        return m;
      }) ?? [],
    });
  }


  return (
    <AppPage
      px={4}
      py={2}>
      <AppPageHeader
        onlyMobile
        title={
          continueChat ? undefined : chatTitle
        }
        updateTitle={false}
        actions={continueChat ? (
            <HStack
              width="calc(100vw - 66px)"
              justify="space-between"
              spacing={0}
            >
              <ExistingChatAssistantButton
                isLoading={assistantsState.loadingById[ assistantId ] || chatsState.loadingById}
                assistant={assistant}
                isSmall
              />
            </HStack>
          ) : undefined
        }
      />
      <VStack
        width="100%"
        height="100%"
        flexGrow={1}
        align="center"
        justify="stretch"
      >
        {
          continueChat && (
            <HStack
              borderBottomWidth="1px"
              borderBottomColor={topbarBorderColor}
              width="100%"
              pb={1}
              justify="space-between"
              height={53}
              display={{ base: 'none', lg: 'flex' }}
            >
              <ExistingChatAssistantButton
                isLoading={assistantsState.loadingById[ assistantId ] || chatsState.loadingById}
                assistant={assistant}
                isSmall={false}
              />
            </HStack>
          )
        }

        {
          !continueChat && (
            <Box
          display={{ base: 'none', lg: 'flex' }}
          sx={{
            justifyContent: 'start',
            width: '100%',
            borderBottomWidth: '1px',
            borderBottomColor: topbarBorderColor,
          }}
        >
          <Text fontSize={20} fontWeight={'600'}>
            {chatTitle}
          </Text>
        </Box>
          )
        }
        
        <ChatMessages chat={chat} onUserScrollUpdate={() => { }} ref={messagesRef} />

        <Flex width="100%" justify="center">
          {
            continueChat &&
            <ChatInput
              onSend={startNewChat}
              onStop={() => { }}
              status='idle'
              loading={pending}
              newChatPending={chatsState.newChatPending}
            />
          }
          {
            !continueChat && (
              <Button
                sx={{ borderRadius: 90 }}
                onClick={() => {
                  navigate(`/share/${threadId}/continue`, { replace: true });
                }}
              >
                Continue this conversation
              </Button>
            )
          }

        </Flex>
      </VStack>
    </AppPage>
  );
};


export default SharedChat;